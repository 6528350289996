import React from 'react';
import Helmet from 'react-helmet';

export default () => (
  <Helmet>
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png?v=PY469lLjGv" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png?v=PY469lLjGv" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png?v=PY469lLjGv" />
    <link rel="manifest" href="/site.webmanifest?v=PY469lLjGv" />
    <link rel="mask-icon" href="/safari-pinned-tab.svg?v=PY469lLjGv" color="#5bbad5" />
    <link rel="shortcut icon" href="/favicon.ico?v=PY469lLjGv" />
    <meta name="msapplication-TileColor" content="#da532c" />
    <meta name="theme-color" content="#ffffff" />
  </Helmet>
);
